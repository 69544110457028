<template>
    <div>
        <table-catalog-documents/>
    </div>
</template>

<script>
import TableCatalogDocuments from '@/components/catalog/documents/table.vue'
export default {
  components: { TableCatalogDocuments },
    data () {
        return {
            
        }
    }
    
}
</script>